<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <!-- <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Available Tasks"
          value="8"
         />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-poll"
          title="Task Completed"
          value="11"
        
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Average Rating"
          value="4.5"
         />
      </v-col>

      


     
    </v-row> -->
<!--     
     <v-data-table
    :headers="headers"
    :items="conversation"
    
    hide-default-footer
    class="elevation-1"
    @click:row="handleItemClick"
  ></v-data-table> -->
  <StatsWidgetComponent/>
  </v-container>
</template>

<script>
import StatsWidgetComponent from "./components/core/StatsWidgetComponent.vue";
  export default {
    name: 'DashboardDashboard',

      components:{
      StatsWidgetComponent
    },

    data () {
      return {
           headers: [
             {
               text:'id',
               value: 'id'
             },
          {
            text: 'Customer Id',
            align: 'start',
            value: 'customerId',
          },
          { text: 'Title', value: 'title' },
          { text: 'Area', value: 'area' },
          { text: 'Environment Type', value: 'environtmentType' },
        
        ],
        conversation: [
         
        ],
      }
    },

    methods: {
      handleItemClick (ev) {
        console.log(ev);
        this.$router.push({path:'/message/'+ev.id})
      },
    
    },

    mounted(){
      
      this.$socket.client.close();
    }
  }
</script>
